import React, { useState, useCallback, FC, useEffect } from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { Form, Input, Checkbox, Button, Modal, message } from 'antd'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import { Daijieru } from '@imile/icons'
import { getUrlParam } from '@/components/PrivacyPolicy/config'
import { submitAPI, sameOrderAPI, baseInfoAPI } from './api'
import { InformationProps } from './interface'

const CheckboxGroup = Checkbox.Group

const useConfirmModal = ({ form, checkedList, setStep, setSubmitInfo, order, idOrigin, t }) => {
  const [open, setOpen] = useState<boolean>(false)

  const handleCancel = useCallback(() => {
    setOpen(false)
  }, [])

  const handleOk = useCallback(async () => {
    const val = form.getFieldsValue()
    const params = {
      ...val,
      waybillNoList: [...checkedList, order],
    }
    if (idOrigin === val.consigneeId || val.consigneeId?.includes('*')) {
      message.warning(`${t.提交失败含有特殊字符}`)
      return
    }
    const res = await submitAPI(params)
    if (res.status === 'success') {
      setStep('showSuccess')
      setSubmitInfo({
        trackingNO: order,
        consigneeId: val.consigneeId,
      })
    } else {
      message.error(res.message || res.status)
    }
  }, [form, checkedList, order, idOrigin])

  const modalContent = (
    <Box
      className='modal-content'
      sx={{
        '.ant-modal-content': {
          borderRadius: '8px',
        },
        '.ant-modal-footer': {
          display: 'flex',
          justifyContent: 'flex-end',
          border: 0,
          padding: '0px 16px 24px',
        },
        '.ant-btn': {
          display: 'block',
          width: 'max-content',
          padding: '6px 16px',
        },
        '.ant-btn-default': {
          bgcolor: '#EDEFF5',
        },
      }}
    >
      <Modal
        width={400}
        title=''
        open={open}
        onOk={handleOk}
        okText={t.确定}
        cancelText={t.取消}
        onCancel={handleCancel}
        closeIcon={<></>}
        getContainer={() => document.querySelector('.modal-content') || document.body}
      >
        <Box
          sx={{
            display: 'flex',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#000000',
            fontWeight: 590,
          }}
        >
          <Daijieru color='#2546FF' fontSize={24} sx={{ mr: '8px' }} />
          {t.确定提交已填写信息吗}
        </Box>
      </Modal>
    </Box>
  )

  return {
    modalContent,
    open,
    setOpen,
  }
}

const content: FC<InformationProps> = ({
  step,
  setStep,
  setSubmitInfo,
  submitInfo,
  informationCollectionLocale: t,
}) => {
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([])
  const [indeterminate, setIndeterminate] = useState(false)
  const [checkAll, setCheckAll] = useState(true)
  const [order, setOrder] = useState<string>('')
  const [sameOrderList, setSameOrderList] = useState<string[]>([])
  const [form] = Form.useForm()
  const [idOrigin, setIdOrigin] = useState<string | undefined>(undefined)
  const isPc = useMediaQuery('@media (min-width: 800px)')

  const { modalContent, setOpen, open } = useConfirmModal({
    form,
    checkedList,
    setStep,
    setSubmitInfo,
    order,
    idOrigin,
    t,
  })

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list)
    setIndeterminate(!!list.length && list.length < sameOrderList.length)
    setCheckAll(list.length === sameOrderList.length)
  }

  const onAllowChange = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`)
  }

  const handleSubmit = () => {
    form
      .validateFields()
      .then(() => {
        const isAgree = form.getFieldValue('isAgree')
        if (!isAgree) {
          message.warning('Please agree')
          return
        }
        setOpen(true)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? sameOrderList : [])
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }

  const handleCancel = () => {
    setStep('add')
  }

  const init = () => {
    const waybillNoBase64 = getUrlParam('waybillNo')
    const waybillNo = window.atob(waybillNoBase64)
    setOrder(waybillNo)
    form.setFieldsValue({
      isAgree: true,
    })
  }

  const querySameOrder = useCallback(async (waybillNo) => {
    const res = await sameOrderAPI(waybillNo)
    if (res.status === 'success') {
      setSameOrderList(res.resultObject || [])
      setCheckedList(res.resultObject || [])
    } else {
      message.error(res.message || res.status)
      setSameOrderList([])
    }
  }, [])

  const queryBaseInfo = useCallback(async (waybillNo) => {
    const res = await baseInfoAPI(waybillNo)
    if (res.status === 'success') {
      form.setFieldsValue({
        consigneeId: res.resultObject || undefined,
      })
      setIdOrigin(res.resultObject || undefined)
    } else {
      message.error(res.message || res.status)
    }
  }, [])

  const agreeNode = (
    <>
      <Form.Item
        label={''}
        name='isAgree'
        rules={[
          {
            required: true,
            message: `${t.请选择}`,
          },
        ]}
        valuePropName='checked'
      >
        <Checkbox onChange={onAllowChange}>
          <Box sx={{ fontSize: '12px', lineHeight: '20px', fontWeight: 400 }}>
            {t.同意条款} <a href=''>{`<${t.隐私政策}>`}</a>, <a href=''>{`<${t.服务协议}>`}</a>
          </Box>
        </Checkbox>
      </Form.Item>
      <Box
        sx={{
          display: 'flex',
          button: {
            mr: '8px',
          },
          '.ant-btn': {
            width: 'max-content',
          },
          '.ant-btn-default': {
            bgcolor: '#EDEFF5',
            fontWeight: 500,
            color: '#101117',
            '&:hover': {
              bgcolor: '#EDEFF5',
              color: '#101117',
            },
          },
        }}
        className='submit-btn'
      >
        <Button type='primary' onClick={handleSubmit}>
          {t.提交}
        </Button>
        {step === 'edit' && (
          <Button type='default' onClick={handleCancel}>
            {t.取消}
          </Button>
        )}
      </Box>
    </>
  )

  useEffect(() => {
    form.setFieldsValue({
      consigneeId: submitInfo?.consigneeId,
    })
  }, [submitInfo, form])

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (order) {
      querySameOrder(order)
      queryBaseInfo(order)
    }
  }, [order])

  return (
    <Box
      sx={{
        padding: ['16px', '24px 40px'],
        mx: isPc ? 0 : '16px',
        bgcolor: 'white',
        flex: 1,
        overflowY: 'auto',
        pb: '200px',
        '.ant-row': {
          display: 'block !important',
        },
        '.ant-input': {
          width: '320px',
          height: '32px',
          bgcolor: '#EDEFF5',
          borderRadius: '4px',
          border: 0,
          '&:hover': {
            border: 0,
          },
          '&:focus': {
            border: 0,
          },
        },
        '.ant-btn': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '4px',
          border: 0,
        },
        '.ant-btn-primary': {
          bgcolor: '#2546FF',
        },
        '.ant-form-item-label > label': {
          color: '#525566',
        },
        '.ant-checkbox-inner': {
          border: '1px solid #d9d9d9',
        },
        '.ant-form-item-has-error': {
          '.ant-input': {
            border: '1px solid #ff4d4f',
          },
          '.ant-checkbox-inner': {
            border: '1px solid #ff4d4f',
          },
        },
      }}
    >
      <Box
        sx={{
          fontWeight: 600,
          fontSize: '18px',
          lineHeight: '26px',
          color: '#000000',
          '.track-no': {
            display: isPc ? 'initial' : 'block',
            fontSize: ['14px', '16px'],
            lineHeight: '24px',
            fontWeight: 400,
            color: '#444757',
            m: isPc ? 0 : '4px 0 8px',
          },
          '.title': {
            mr: '4px',
          },
        }}
      >
        <span className='title'>{t.信息收集}</span>
        <span className='track-no'>{`(${t.运单号}: ${order})`}</span>
      </Box>
      <Box
        sx={{
          color: '#878B9C',
          size: '14px',
          fontWeight: 400,
          lineHeight: '20px',
          mt: '4px',
          mb: '24px',
        }}
      >
        {t.请填写}
      </Box>
      <Form form={form}>
        <Form.Item
          label={`${t.身份证或护照ID}`}
          name='consigneeId'
          rules={[
            {
              required: true,
              message: `${t.请输入} ${t.身份证或护照ID}`,
            },
            {
              max: 100,
              message: `${t.身份证或护照ID} ${t.最多100个字符}`,
            },
          ]}
        >
          <Input />
        </Form.Item>
        {!form.getFieldValue('consigneeId') && sameOrderList?.length ? (
          <Box
            sx={{
              '.ant-checkbox-group-item': {
                width: isPc ? 'initial' : '40%',
                mb: '12px',
              },
            }}
          >
            <Box sx={{ fontSize: '14px', lineHeight: '22px', mt: '24px', mb: '12px' }}>
              {t.请勾选}
            </Box>
            <Form.Item label={''} name='waybillNoList' valuePropName='checked'>
              <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
              >
                {t.全选}
              </Checkbox>
              <Box sx={{ mt: '12px' }}></Box>
              <CheckboxGroup options={sameOrderList} value={checkedList} onChange={onChange} />
            </Form.Item>
          </Box>
        ) : null}
        <Box sx={{ mt: '24px' }}></Box>
        <Box
          sx={{
            '&': isPc
              ? {}
              : {
                  position: 'fixed',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  padding: '12px 16px 48px',
                  borderTop: '1px solid #E4E5ED',
                  bgcolor: '#FFFFFF',
                  '.submit-btn': {
                    width: '100%',
                    button: {
                      width: '100%',
                      height: '48px',
                      borderRadius: '6px',
                      fontSize: '16px',
                    },
                  },
                },
          }}
        >
          {agreeNode}
        </Box>
      </Form>
      {open && modalContent}
    </Box>
  )
}
export default content
