import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Header from '@/components/InformationCollection/header'
import Content from '@/components/InformationCollection/content'
import useSuccessPage from '@/components/InformationCollection/useSuccessPage'
import { Box, useMediaQuery } from '@mui/material'
import { InformationProps, Details } from '@/components/InformationCollection/interface'

const InformationCollection = (pageProps) => {
  const [step, setStep] = useState<'add' | 'edit' | 'showSuccess'>('add')
  const [submitInfo, setSubmitInfo] = useState<Details>({
    trackingNO: '',
    consigneeId: '',
  })
  const isPc = useMediaQuery('@media (min-width: 800px)')
  const informationCollectionLocale = JSON.parse(pageProps.data?.informationCollectionLocale?.data)

  const props: InformationProps = {
    step,
    submitInfo,
    setStep,
    setSubmitInfo,
    informationCollectionLocale,
  }

  const { content } = useSuccessPage(props)

  return (
    <Box sx={{ width: '100%', height: '100vh', bgcolor: '#E4E5ED' }}>
      <Box
        sx={{ display: 'flex', justifyContent: 'center', padding: '16px 0 24px', height: '100%' }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: isPc ? '900px' : '100%',
            borderRadius: '8px',
            bgcolor: isPc ? 'white' : '#E4E5ED',
            height: '100%',
          }}
        >
          <Header />
          {step === 'showSuccess' ? content : <Content {...props} />}
        </Box>
      </Box>
    </Box>
  )
}
export default InformationCollection

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["home"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
        }
      }
    }
    commonLocale: locale(ns: { eq: "common" }, language: { eq: $language }) {
      data
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    informationCollectionLocale: locale(
      ns: { eq: "informationCollection" }
      language: { eq: $language }
    ) {
      data
    }
  }
`
