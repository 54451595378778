import React from 'react'
import { Dropdown, Menu } from 'antd'
import Cookies from 'js-cookie'
import { Box, useMediaQuery } from '@mui/material'
import { useI18next } from 'gatsby-plugin-react-i18next'
import { getDomain } from '@/utils/index'

const header = () => {
  const isPc = useMediaQuery('@media (min-width: 800px)')
  const { changeLanguage } = useI18next()
  const domain = getDomain()
  const langValue = Cookies.get('EXPRESS_LANG')

  const handleClick = (item) => {
    changeLanguage(item.i18nLang)
    Cookies.set('EXPRESS_LANG', item.code, { domain: domain, path: '/' })
  }

  const menuInit = [
    {
      key: '1',
      value: 'English',
      i18nLang: 'en',
      code: 'en_US',
    },
    {
      key: '2',
      value: '简体中文',
      i18nLang: 'zh',
      code: 'zh_CN',
    },
  ]

  const menuList = menuInit.map((item) => ({
    ...item,
    label: (
      <a
        target='_blank'
        rel='noopener noreferrer'
        onClick={() =>
          handleClick({
            i18nLang: item.i18nLang,
            code: item.code,
          })
        }
      >
        {item.value}
      </a>
    ),
  }))

  const menu = <Menu items={menuList} />

  const showLang = menuInit.find(item => item.code === langValue)?.value

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '50px',
        bgcolor: '#2546FF',
        borderRadius: '8px 8px 0 0',
        padding: '0 16px',
        mx: isPc ? 'initial'  : '16px',
      }}
    >
      <Box
        component='img'
        src='/images/imile-icon.svg'
        sx={{ width: '56px', height: '20px' }}
      ></Box>
      <Dropdown overlay={menu} placement='bottomRight'>
        <Box
          sx={{
            span: {
              color: '#FFFFFF',
              ml: '8px',
              fontSize: '12px',
            },
          }}
        >
          <Box component='img' src='/images/translate.svg'></Box>
          <span>{showLang}</span>
        </Box>
      </Dropdown>
    </Box>
  )
}
export default header
